html, body {
  background-color: white;
}

@media print {
  body * { 
    display: none; 
    overflow: hidden;
  
  }
  .print-area, .print-area * { display: block; }
  .non-print-area, .non-print-area * { display: none; }
}

#support-table {
  width: 100%;
  border: 1px solid #bdbdbd;
  border-collapse: collapse;
}

#support-table th {
  border: 1px solid #bdbdbd;
  background-color: #eceff1;
}

#support-table td {
  width: 12.5%;
  border: 1px solid #bdbdbd;
}

#support-table tbody td {
  text-align: center;
}

#support-table tr:nth-child(even) {
  background-color: #e1f5fe;
}

#support-table thead tr th {
  font-size: 1.3em;
  padding: 0.6em;
}

#support-table tbody tr th {
  padding: 0.6em;
}

#support-table tbody tr th span {
  font-size: 1.3em;
}

#support-table tbody tr th img {
  width: 3em;
  height: 3em;
}
